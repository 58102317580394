import Vue from 'vue/dist/vue.esm';
import axios from 'axios';
import _ from 'lodash';
import VueSelect from 'vue-select'

let token = document.getElementsByName('csrf-token')[0].getAttribute('content');
axios.defaults.headers.common['X-CSRF-Token'] = token;
axios.defaults.headers.common['Accept'] = 'application/json';

Vue.component('v-select', VueSelect)

document.addEventListener('DOMContentLoaded', () => {
  $(".vue-follow").each(function() {
    var data = $(this).data()

    new Vue({
      el: this,
      data: {
        ...data,
        fund: null,
        options: [],
        followed: []
      },
      mounted() {
        this.options = this.funds
      },
      methods: {
        close() {
          const dest = $("body").data("destination")
          
          if(dest) {  
            window.location.assign(dest);
          } else {
            window.location.reload()
          }
        },
        toggle(fund_id) {
          if(this.followed.includes(fund_id)) {
            this.unfollow(fund_id)
          } else {
            this.follow(fund_id)
          }
        },
        unfollow(fund_id) {
          this.followed = _.without(this.followed,fund_id)
          axios.get(`/api/follow/unfollow?fund_id=${fund_id}`)
        },
        follow(fund_id) {
          axios.get(`/api/follow/follow?fund_id=${fund_id}`)
          if(!this.followed.includes(fund_id)) {
            this.followed.push(fund_id)
          }
        },
        onSearch(search, loading) {
          loading(true)
          this.search(loading, search, this)
        },
        onChange(a, b) {
          if(a) {
            if(!this.followed.includes(a.id)) {
              this.follow(a.id)
            }
            if(!_.find(this.funds, {id: a.id})) {
              this.funds.push(a)
            }
            this.$refs.vueselect.search = ""
            this.fund = null
          }
        },
        reset() {
          this.fund = null
        },
        search: _.debounce((loading, search, vm) => {
          axios.get(`/api/follow/search?q=${escape(search)}`).then(res => {
            if(res.data.funds.length == 0) {
              this.fund = null
            }
            vm.options = res.data.funds
            loading(false)
          })
        })
      }
    });
  })
})